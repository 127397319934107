import React from "react";
import "./roomdata.scss";
import { DataGrid } from "@mui/x-data-grid";
import { roomColumns, roomRows } from '../../roomDatabase';
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";


const RoomData = () => {

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 160,
      renderCell: () => {
        return (
          <div className="cellAction">
            <VisibilityOutlinedIcon className="viewButton" />
            <DeleteOutlineOutlinedIcon className="deleteButton" />
            <ModeEditOutlineOutlinedIcon className="editButton" />
          </div>
        );
      }
    }
  ];
  return (
    <div className="roomData">
      <DataGrid
        rows={roomRows}
        columns={roomColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </div>
  );

}



export default RoomData;