import React from "react";
import "./booking.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Table from "../../components/table/Table";


const Booking = () => {

  return (
    <div className="booking">
      <Sidebar />
      <div className="bookingContainer">
        <Navbar />
        <div className="listTitle">All Bookings</div>
        <div className="listContainer">
          <Table />
        </div>
      </div>
    </div>
  );
};


export default Booking;