import React from "react";
import "./roomlist.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import RoomData from "../../../components/roomdata/RoomData";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";


const RoomList = () => {

  return (
    <div className="room">
      <Sidebar />
      <div className="roomListContainer">
        <Navbar />
        <div className="listTitle">
          All Rooms
          <Link to="/rooms/add" className="buttonLink">
            <Button
              variant="contained"
              className="button"
              endIcon={<AddOutlinedIcon />}
            >
              Add Room
            </Button>
          </Link>
        </div>
        <RoomData />
      </div>
    </div>
  );
};

export default RoomList;