import React from 'react';
import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, userRows } from '../../databasesource';
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";


const Datatable = () => {

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: () => {
        return (
          <div className="cellAction">
            <VisibilityOutlinedIcon className="viewButton" />
            <DeleteOutlineOutlinedIcon className="deleteButton" />
            {/* <div className="viewButton">View</div> */}
            {/* <div className="deleteButton">Delete</div> */}
          </div>
        );
      }
    }
  ];
  return (
    <div className="datatable">

      <DataGrid
        rows={userRows}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
}

export default Datatable;