import React from 'react';
import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";


const HomeTable = () => {

  const rows = [
    {
      id: 11223344,
      room: "1 X Classics",
      img: "../assets/images/1.png",
      customer: "Astor Samuel",
      arr: "17 July 22",
      dep: "22 July 22",
      amount: 25000,
      method: "Online Payment",
      status: "Checked-out",
    },

    {
      id: 33226677,
      room: "2 X Executive",
      img: "../assets/images/2.png",
      customer: "Jane Will",
      arr: "17 July 22",
      dep: "22 July 22",
      amount: 30000,
      method: "Online Payment",
      status: "Canceled",
    },

    {
      id: 55221156,
      room: "3 X Standard",
      img: "../assets/images/5.png",
      customer: "John Olumide",
      arr: "17 July 22",
      dep: "22 July 22",
      amount: 22000,
      method: "Online Payment",
      status: "Checked-in",
    },

    {
      id: 99221156,
      room: "3 X Suites",
      img: "../assets/images/2.png",
      customer: "Bob Chukwu",
      arr: "17 July 22",
      dep: "22 July 22",
      amount: 140000,
      method: "Online Payment",
      status: "Pending",
    },
  ];
  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Booking ID</TableCell>
            <TableCell className="tableCell">Room</TableCell>
            <TableCell className="tableCell">Customer</TableCell>
            <TableCell className="tableCell">Arr</TableCell>
            <TableCell className="tableCell">Dep</TableCell>
            <TableCell className="tableCell">Amount</TableCell>
            <TableCell className="tableCell">Payment Method</TableCell>
            <TableCell className="tableCell">Booking Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell className="tableCell">{row.id}</TableCell>
              <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img src={row.img} alt="" className="image" />
                  {row.room}
                </div>
              </TableCell>
              <TableCell className="tableCell">{row.customer}</TableCell>
              <TableCell className="tableCell">{row.arr}</TableCell>
              <TableCell className="tableCell">{row.dep}</TableCell>
              <TableCell className="tableCell">{row.amount}</TableCell>
              <TableCell className="tableCell">{row.method}</TableCell>
              <TableCell className="tableCell">
                <span className={`status ${row.status}`}>{row.status}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default HomeTable;