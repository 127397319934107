import React from 'react';
import "./widget.scss";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
 
const Widget = ({ type }) => {
  let data;

  // temporary number
  const amount = 10000
  const diff = 20
  switch (type) {
    case "user":
      data = {
        title: "CUSTOMERS",
        isMoney: false,
        link: "See all customers",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "#96CEB4",
              backgroundColor: "#FFEEAD",
            }}
          />
        ),
      };
      break;

    case "booking":
      data = {
        title: "ROOMS SOLD",
        isMoney: false,
        link: "View all bookings",
        icon: (
          <HotelOutlinedIcon
            className="icon"
            style={{
              color: "#146356",
              backgroundColor: "#A3DA8D",
            }}
          />
        ),
      };
      break;

    case "earning":
      data = {
        title: "EARNINGS",
        isMoney: true,
        link: "View net earnings",
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{
              color: "#00A19D",
              backgroundColor: "#64C9CF",
            }}
          />
        ),
      };
      break;

    case "balance":
      data = {
        title: "BALANCE",
        isMoney: true,
        link: "See details",
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              color: "#654062",
              backgroundColor: "#FFD66B",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }


  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "₦"} {amount}
        </span>
        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpOutlinedIcon />
          {diff} %
        </div>
        {data.icon}
      </div>
    </div>
  );
}

export default Widget;