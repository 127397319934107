import React,{useState} from 'react';
import './new.scss';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";

const New = ({ inputs, title }) => {
  
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1 className="title">{title} </h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              alt=""
            />
          </div>
          <div className="right">
            <form>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input type="file" id="file"  style={{ display: "none" }} />
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
               <label>{input.label}</label>
                <input type={input.type} placeholder={input.placeholder} />
             </div>
              ))}


              {/* <div className="formInput">
                <label>Full Name</label>
                <input type="text" placeholder="full name" />
              </div>
              <div className="formInput">
                <label>Email</label>
                <input type="email" placeholder="email" />
              </div>
              <div className="formInput">
                <label>Phone</label>
                <input type="email" placeholder="+234 774 333 2222" />
              </div>
              <div className="formInput">
                <label>Password</label>
                <input type="password" placeholder="enter password" />
              </div>

              <div className="formInput">
                <label>Address</label>
                <input type="text" placeholder="enter address" />
              </div>

              <div className="formInput">
                <label>Country</label>
                <input type="text" placeholder="enter country" />
              </div> */}

              <button>Create</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default New;