import React from 'react';
import "./single.scss";  
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import HomeTable from "../../components/table/Table";
import Chart from "../../components/chart/Chart";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const Single = () => {
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />

        <div className="top">
          <div className="left">
            <div className="editButton">
              <EditOutlinedIcon />
            </div>
            <h1 className="title">Hotel Information</h1>
            <div className="item">
              <img src="../assets/images/adm.jpg" alt="" className="itemImg" />
              <div className="details">
                <h1 className="itemTitle">Hotel La gos</h1>
                <h4 className="itemTitle">Manager: Dan Dev</h4>
                <div className="detailItem">
                  <span className="itemKey">Hotel Email:</span>
                  <span className="itemValue"> dandev@a ol.com</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Hotel Phone:</span>
                  <span className="itemValue">+2348134996425</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Hotel Address:</span>
                  <span className="itemValue">
                    {" "}
                    Money St. 100 Eko Rd. Lagos Nigeria
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue"> Nigeria</span>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <Chart aspect={3 / 1} title="Hotel Earnings (Last 6  Months)" />
          </div>
        </div>
        <div className="bottom">
          <h1 className="title">Last Transaction</h1>
          <HomeTable />
        </div>
      </div>
    </div>
  );
}

export default Single