import React, { useEffect, useState } from "react";
import "./addroom.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";



const AddRoom = () => {


  return (
    <div className="addRoom">
      <Sidebar />
      <div className="addContainer">
        <Navbar />
        <div className="top">
          <h1 className="title">Add Room Categories</h1>
        </div>

        <div className="bottom">
          <Box sx={{ display: "flex", flex: "wrap" }}>
            <div className="form">
              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <InputLabel htmlFor="">Room Number</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  endAdornment={
                    <InputAdornment position="end"></InputAdornment>
                  }
                  label="Room Number"
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <InputLabel htmlFor="">Room Number</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  endAdornment={
                    <InputAdornment position="end"></InputAdornment>
                  }
                  label="Room Number"
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <InputLabel htmlFor="">Room Number</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  endAdornment={
                    <InputAdornment position="end"></InputAdornment>
                  }
                  label="Room Number"
                />
              </FormControl>
            </div>
          </Box>

          <div className="button">
            <Button variant="contained" size="medium">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}


export default AddRoom;