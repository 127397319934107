import React from "react";

// temporary data
export const roomColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 70,
  },
  {
    field: "category",
    headerName: "Room Category",
    width: 220,
    renderCell: (params) => {
      return (
        <>
          <div className="cellWithImg">
            <img className="cellImg" src={params.row.img} alt="avatar" />
            {params.row.category}
          </div>
        </>
      );
    },
  },

  {
    field: "count",
    headerName: "No. Available Rooms",
    width: 200,
  },
  
  {
    field: "total",
    headerName: "Total No. Rooms",
    width: 200,
  },

  {
    field: "status",
    headerName: "Room Status",
    width: 100,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },
  },
];


// temporary data

export const roomRows = [
  {
    id: 1,
    category: "Standard Rooms",
    img: "../assets/images/1.png",
    total: "5",
    count: "4",
    status: "available",
  },

  {
    id: 2,
    category: "Classic Rooms",
    img: "../assets/images/2.png",
    total: "5",
    count: "2",
    status: "available",
  },

  {
    id: 3,
    category: "Executive Rooms",
    img: "../assets/images/4.png",
    total: "5",
    count: "0",
    status: "no-vacancy",
  },

  {
    id: 4,
    category: "Executive Room",
    img: "../assets/images/5.png",
    total: "5",
    count: "2",
    status: "available",
  },

  {
    id: 5,
    category: "Executive Suites Room",
    img: "../assets/images/1.png",
    total: "5",
    count: "0",
    status: "maintainance",
  },

  {
    id: 6,
    category: "Presidential Room",
    img: "../assets/images/4.png",
    total: "5",
    count: "1",
    status: "available",
  },
];

