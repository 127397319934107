import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home/Home';
import Login from "./pages/auth/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single"
import Booking from "./pages/booking/Booking";
import RoomList from "./pages/room/roomList/RoomList";
import New from "./pages/new/New";
import AddRoom from "./pages/room/addroom/AddRoom"
import {userInputs} from "./formSource";


const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="booking" element={<Booking />} />
          </Route>

          <Route path="users">
            <Route index element={<List />} />
            <Route path=":userId" element={<Single />} />
            <Route
              path="new"
              element={<New inputs={userInputs} title="Update Hotel Profile" />}
            />
          </Route>

          <Route path="rooms">
            <Route index element={<RoomList />} />
            <Route path=":roomId" element={<Single />} />
            <Route path="add" element={<AddRoom />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};
export default App;
