import React from 'react';


// temporary data
export const userColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      return (
        <>
          <div className="cellWithImg">
            <img className="cellImg" src={params.row.img} alt="avatar" />
            {params.row.username}
          </div>
        </>
      );
    },
  },

  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "age",
    headerName: "Age",
    width: 100,
  },

  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return <div className={`cellWithStatus ${params.row.status}`}>{params.row.status}</div>;
    }
  },
];


// temporary data
export const userRows = [
  {
    id: 1,
    username: "Snow",
    img: "../assets/user/1.png",
    email: "snow@aol.com",
    status: "active",
    age: 35,
  },
  {
    id: 2,
    username: "Lannister",
    img: "../assets/user/2.png",
    email: "lannister@aol.com",
    status: "passive",
    age: 42,
  },
  {
    id: 3,
    username: "Lanny",
    img: "../assets/user/3.png",
    email: "lan@aol.com",
    status: "active",
    age: 45,
  },
  {
    id: 4,
    username: "Stark",
    img: "../assets/user/4.png",
    email: "stark@aol.com",
    status: "pending",
    age: 16,
  },
  {
    id: 5,
    username: "Targaryen",
    img: "../assets/user/5.png",
    email: "targaryen@aol.com",
    status: "active",
    age: 20,
  },
  {
    id: 6,
    username: "Melisandre",
    img: "../assets/user/6.png",
    email: "melis@aol.com",
    status: "passive",
    age: 50,
  },
  {
    id: 7,
    username: "Clifford",
    img: "../assets/user/7.png",
    email: "clifford@aol.com",
    status: "active",
    age: 44,
  },
  {
    id: 8,
    username: "Frances",
    img: "../assets/user/8.png",
    email: "frances@aol.com",
    status: "passive",
    age: 36,
  },
  {
    id: 9,
    username: "Roxie",
    img: "../assets/user/4.png",
    email: "rox@aol.com",
    status: "active",
    age: 65,
  },

  {
    id: 10,
    username: "Rose",
    img: "../assets/user/6.png",
    email: "rosy@aol.com",
    status: "pending",
    age: 65,
  },
];